import React from "react";

const NewsletterSignupForm = () => (
  <div id="mc_embed_signup" className="newsletter-signup">
    <form
      action="//andyet.us9.list-manage.com/subscribe/post?u=faa323952110d5be6830f05f5&amp;id=3aad4805ae&SIGNUP=AndyetBlog"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
      className="validate content-left-aligned newsletter-form"
    >
      <fieldset id="mc_embed_signup_scroll">
        <h3 className="center">Strategy in <strong>your inbox</strong></h3>
        <p>
          We send out a regular newsletter about how people-first companies can use creative technology to solve pressing business problems.
        </p>
        <p>
          When you sign up, <strong>we’ll send you a copy of our framework</strong> for building and strengthening customer relationships, supported by 15 years of research. We hope you’ll join us.
        </p>
        <div className="form-element mc-field-group">
          <label htmlFor="mce-FNAME">Name</label>
          <input
            name="FNAME"
            id="mce-FNAME"
            type="text"
            placeholder="What can we call you?"
            className="form-input"
          />
        </div>
        <div className="form-element mc-field-group">
          <label htmlFor="mce-EMAIL">Your Email*</label>
          <input
            id="smce-EMAIL"
            name="EMAIL"
            type="email"
            required="required"
            placeholder="you@reawesome.com"
            className="form-input"
          />
        </div>
        <div id="subscribe-interests" className="mc-field-group input-group">
          <label>I’m interested in</label>
          <ul className="list-unstyled">
            <li>
              <input
                type="checkbox"
                value="1"
                name="group[38009][1]"
                id="mce-group[38009]-38009-0"
                checked
              />
              <label for="mce-group[38009]-38009-0">Updates from the &amp;yet team</label>
            </li>
            <li>
              <input
                type="checkbox"
                value="2"
                name="group[38009][2]"
                id="mce-group[38009]-38009-1"
                checked
              />
              <label for="mce-group[38009]-38009-1">Education on strengthening customer relationships through creative technology</label>
            </li>
          </ul>
        </div>
        <div id="mce-responses">
          <div
            className="response"
            id="mce-error-response"
            style={{ display: "none" }}
          />
          <div
            className="response"
            id="mce-success-response"
            style={{ display: "none" }}
          />
        </div>
        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_faa323952110d5be6830f05f5_3aad4805ae"
            tabIndex="-1"
            value=""
          />
        </div>
        <div>
          <input
            type="submit"
            value="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="button button-primary"
          />
        </div>
      </fieldset>
    </form>
  </div>
);

export default NewsletterSignupForm;
