import React from "react";
import Link from "gatsby-link";

const RelatedPosts = (props) => (
  <div className="postContent">
    <h4 id="you-might-also-enjoy-reading">You might also enjoy reading:</h4>

    <ul>
      {props.posts.map((post) => (
        <li key={post.node.frontmatter.title}>
          <Link to={post.node.fields.permalink}>
            {post.node.frontmatter.title}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default RelatedPosts;
